<template lang="pug">
layout
  side-bar-left
  DashboardPanel
</template>
<script>
//Import organisms components
import Layout from "@/components/organisms/Layout.vue";

//Import custom components of this component
import SideBarLeft from "@/components/customComponents/Dashboard/SideBarLeft.vue";
import DashboardPanel from "@/components/customComponents/Dashboard/DashboardPanel.vue";

export default {
  name: "Panel",
  components: {
    Layout,
    SideBarLeft,
    DashboardPanel
  }
};
</script>
