<template lang="pug">
layout-content
  .dashboard-container
    .dashboard-titles-container
      div
        app-h2(content="Bienvenido al Panel de Administración")
      app-text(
        className="regular dashboard-titles-date"
        :customStyle="{ color:'var(--grey-color)'}"
        :content="dateToday()"
      )

    app-h2(:customStyle="{ marginTop: '7%' }", content="Próximos ShowRooms")
    .showrooms-container
    div(v-if="!loading",:style="{width:'100%',display:'flex',flexWrap:'wrap'}")
      app-h1(
        v-if="toBePresentedShowroomList.length === 0"
        :customStyle="{ margin: '60px auto' ,fontSize:'2rem',color:'var(--grey-color)',textAlign:'center'}",
        content="No hay ShowRooms por presentar"
      )
      card(
        v-else
        className="dashboard-panel"
        :customStyle="{ margin: '2% 1%' }",
        :key="showroom.id",
        v-for="showroom in toBePresentedShowroomList"
      )
          div
            app-text(
              :customStyle="{ background: 'inherit', margin: '7% 0 0 4%' }",
              className="regular",
              content="ShowRoom"
            )
            app-text(
              :customStyle="{ margin: '20% 2% 0', background: 'inherit' }",
              className="small text-info",
              :content="new Date(showroom.scheduled_for).toLocaleString()"
            )
          div(:style="{flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'space-evenly',margin:'1rem 0 0 0',padding:'0 2rem'}")
            app-text(
              v-if="showroom.attendants.length == 0"
              :customStyle="{fontSize:'24px',color:'var(--grey-color)'}",
              className="nano",
              content="No Attendants"
            )
            div(
              v-else
              :key="idx"
              v-for="(attendant,idx) in showroom.attendants.slice(0,2)"
            )
              app-text(
                className="nano",
                :content="attendant.client.email"
              )
              br
              app-text(
                className="nano",
                :content="attendant.client.name"
              )
          app-button(
            :customStyle="{ position:'absolute' , bottom:'1rem',right:'1rem'}",
            className="info",
            :handler="() => startShowRoom(showroom.id)"
            shape="circle",
            icon="caret-right"
          )
    div(
      v-else,
      :style="{width:'100%',display:'flex',flexWrap:'wrap'}"
    )
      a-skeleton(
        :key="idx",
        :active="true",
        :style="{width:'450px',margin:'1rem 0'}",
        v-for="idx in 4"
      )
</template>
<script>
//Import assets
import imageMock from "@/assets/ImageMock.jpg";

//Import atoms components
import AppH2 from "@/components/atoms/AppH2.vue";
import AppH1 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppButton from "@/components/atoms/AppButton.vue";

//Import molecules components
import Card from "@/components/molecules/Card.vue";

//Import organisms components
import LayoutContent from "@/components/organisms/LayoutContent.vue";

import { mapGetters } from "vuex";
import {
  FETCH_TO_BE_PRESENTED_SHOWROOM_LIST,
  START_SHOWROOM_PRESENTATION
} from "@/store/actions.type";
//Import helpers
import { dateToday } from "@/common/helpers.js";
import colorService from "@/common/color.service";
import { THEME } from "@/store/actions.type";
export default {
  name: "DashboardPanel",
  components: {
    AppH1,
    AppH2,
    AppText,
    LayoutContent,
    Card,
    AppImage,
    AppButton
  },
  data() {
    return {
      imageMock,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["isLoadingShowRoomPresent"]),
    toBePresentedShowroomList() {
      let showrooms = this.$store.getters.toBePresentedShowroomList || [];
      return showrooms.slice(0, 4);
    }
  },
  async mounted() {
    colorService.changeTheme(this.$session.get("theme"));
    this.$store.dispatch(THEME, this.$session.get("theme"));
    if (this.$store.getters.toBePresentedShowroomList === null) {
      this.loading = true;
      await this.$store.dispatch(FETCH_TO_BE_PRESENTED_SHOWROOM_LIST);
      this.loading = false;
    }
  },
  methods: {
    async startShowRoom(id) {
      if (this.isLoadingShowRoomPresent) return;
      await this.$store.dispatch(START_SHOWROOM_PRESENTATION, id);
      this.$session.set("showRoomComercialId", id);
      this.$router.push({ name: "ShowRoomComercial", params: { id } });
    },
    dateToday
  }
};
</script>
<style lang="less" scoped>
.dashboard-container {
  margin: 100px 30px 0;
}

.showrooms-container {
  display: flex;
  flex-wrap: wrap;
}

.showrooms-panel-card-text-container {
  margin-top: 5%;
}

.dashboard-titles-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dashboard-titles-date {
  text-align: right;
}
</style>
